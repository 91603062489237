(function () {
  var _typeof =
      typeof Symbol == "function" && typeof Symbol.iterator == "symbol"
        ? function (canCreateDiscussions) {
            return typeof canCreateDiscussions;
          }
        : function (obj) {
            return obj &&
              typeof Symbol == "function" &&
              obj.constructor === Symbol &&
              obj !== Symbol.prototype
              ? "symbol"
              : typeof obj;
          },
    themeJsInit;

  function onload() {
    function init() {
      if (!_0xF6D5) {
        _0xF6D5 = !0;
        document.querySelectorAll(match[3]).forEach(function (img) {
          var datasrc = img.dataset.src;
          if (datasrc != null) {
            img.src = datasrc;
          }
        });
        var rows = document.getElementsByTagName(match[4]),
          i = 0;
        for (i = 0; i < rows.length; i++) {
          if (rows[i].getAttribute(match[5]) !== null) {
            rows[i].setAttribute(match[6], rows[i].getAttribute(match[5]));
            delete rows[i].dataset.src;
          }
          if (match[7] == rows[i].getAttribute(match[8])) {
            var elem = document.createElement(match[4]),
              aidx = 0;
            for (aidx = 0; aidx < rows[i].attributes.length; aidx++) {
              var attr = rows[i].attributes[aidx];
              elem.setAttribute(attr.name, attr.value);
            }
            elem.type = match[9];
            elem.innerHTML = rows[i].innerHTML;
            rows[i].parentNode.removeChild(rows[i]);
            rows[i].parentNode.insertBefore(elem, rows[i]);
          }
        }
        var targets = document.getElementsByTagName(match[10]);
        for (i = 0; i < targets.length; i++) {
          if (targets[i].getAttribute(match[11]) !== null) {
            targets[i].setAttribute(
              match[12],
              targets[i].getAttribute(match[11])
            );
            delete targets[i].dataset.href;
          }
        }
        document.dispatchEvent(new CustomEvent(match[13]));
        setTimeout(function () {
          document.dispatchEvent(new CustomEvent(match[14]));
        }, 1000);
      }
    }

    var _0xF6D5 = !1;
    window.addEventListener(match[15], function () {
      init();
    });
    window.addEventListener(match[16], function () {
      init();
    });
    window.addEventListener(match[17], function () {
      init();
    });
    window.addEventListener
      ? window.addEventListener(match[18], function () {}, !1)
      : window.attachEvent
      ? window.attachEvent(match[19], function () {})
      : (window.onload = function () {});
  }

  var match = [
    "undefined",
    "Unauthorised use of code detected. Refrain from using the copyrighted code without prior permission.",
    "iframe.lazy",
    "script",
    "data-src",
    "src",
    "lazyloadscript",
    "type",
    "text/javascript",
    "link",
    "data-href",
    "href",
    "StartKernelLoading",
    "asyncLazyLoad",
    "scroll",
    "mousemove",
    "touchstart",
    "load",
    "onload",
  ];

  typeof Shopify < "u" &&
    _typeof(Shopify.shop) !== match[0] &&
    (themeJsInit = onload);

  (function () {
    console.log(
      "speedy-test.js is running... this anonymous function is running ... 2.js ..."
    );
    if (navigator.platform == "Linux x86_64") {
      new MutationObserver((e) => {
        e.forEach(({ addedNodes: e2 }) => {
          e2.forEach((e3) => {
            e3.nodeType === 1 && e3.tagName;
          });
        });
      }).observe(document.documentElement, { childList: !0, subtree: !0 });
    }
  })();

  if (navigator.platform == "Linux x86_64") {
    var YETT_BLACKLIST = [
      /f.vimeocdn.com/,
      /base/,
      /klaviyo/,
      /orange/,
      /stamped/,
      /extensions/,
      /apps/,
      /boomerang/,
      /storefront/,
      /browser/,
      /modern/,
      /googletagmanager/,
      /cart/,
      /scripts/,
      /global/,
      /currencies/,
      /fonts/,
      /yotpo/,
      /tiktok/,
      /yieldify/,
      /code/,
      /bing/,
      /gstatic/,
      /acsbapp/,
      /app/,
      /tabarn/,
      /d2rp1k1dldbai6/,
      /cloudfront/,
      /ndnapps/,
      /iubenda/,
    ];

    (function (t, e) {
      typeof exports == "object" && typeof module < "u"
        ? e(exports)
        : typeof define == "function" && define.amd
        ? define(["exports"], e)
        : e((t.yett = {}));
    })(this, function (t) {
      "use strict";
      var e = {
          blacklist: window.YETT_BLACKLIST,
          whitelist: window.YETT_WHITELIST,
        },
        r = { blacklisted: [] },
        i = function (t2, r2) {
          return (
            t2 &&
            (!r2 || r2 !== "javascript/blocked") &&
            (!e.blacklist ||
              e.blacklist.some(function (e2) {
                return e2.test(t2);
              })) &&
            (!e.whitelist ||
              e.whitelist.every(function (e2) {
                return !e2.test(t2);
              }))
          );
        },
        n = function (t2) {
          var r2 = t2.getAttribute("src");
          return (
            (e.blacklist &&
              e.blacklist.every(function (t3) {
                return !t3.test(r2);
              })) ||
            (e.whitelist &&
              e.whitelist.some(function (t3) {
                return t3.test(r2);
              }))
          );
        },
        c = new MutationObserver(function (t2) {
          t2.forEach(function (t3) {
            for (var e2 = t3.addedNodes, n2 = 0; n2 < e2.length; n2++)
              (function (t4) {
                var n3,
                  c2 = e2[t4];
                c2.nodeType === 1 &&
                  c2.tagName === "SCRIPT" &&
                  i(c2.src, c2.type) &&
                  (r.blacklisted.push(c2.cloneNode()),
                  (c2.type = "javascript/blocked"),
                  c2.addEventListener("beforescriptexecute", function t5(e3) {
                    c2.getAttribute("type") === "javascript/blocked" &&
                      e3.preventDefault(),
                      c2.removeEventListener("beforescriptexecute", t5);
                  }),
                  c2.parentElement.removeChild(c2));
              })(n2);
          });
        });
      c.observe(document.documentElement, { childList: !0, subtree: !0 });
      var a = document.createElement;
      document.createElement = function () {
        for (var t2 = arguments.length, e2 = Array(t2), r2 = 0; r2 < t2; r2++)
          e2[r2] = arguments[r2];
        if (e2[0].toLowerCase() !== "script")
          return a.bind(document).apply(void 0, e2);
        var n2 = a.bind(document).apply(void 0, e2),
          c2 = n2.setAttribute.bind(n2);
        return (
          Object.defineProperties(n2, {
            src: {
              get: function () {
                return n2.getAttribute("src");
              },
              set: function (t3) {
                return (
                  i(t3, n2.type) && c2("type", "javascript/blocked"),
                  c2("src", t3),
                  !0
                );
              },
            },
            type: {
              set: function (t3) {
                return (
                  c2("type", i(n2.src, n2.type) ? "javascript/blocked" : t3), !0
                );
              },
            },
          }),
          (n2.setAttribute = function (t3, e3) {
            t3 === "type" || t3 === "src"
              ? (n2[t3] = e3)
              : HTMLScriptElement.prototype.setAttribute.call(n2, t3, e3);
          }),
          n2
        );
      };
      var o = function (t2) {
          if (Array.isArray(t2)) {
            for (var e2 = 0, r2 = Array(t2.length); e2 < t2.length; e2++)
              r2[e2] = t2[e2];
            return r2;
          }
          return Array.from(t2);
        },
        l = RegExp("[|\\{}()[\\]^$+?.]", "g");
      t.unblock = function () {
        for (var t2 = arguments.length, e2 = Array(t2), i2 = 0; i2 < t2; i2++)
          e2[i2] = arguments[i2];
        var a2 = !0;
        if (e2.length === 0) {
          (r.blacklisted = []), (a2 = !1);
          var s2 = document.querySelectorAll(
            "script[type='javascript/blocked']"
          );
          s2.forEach(function (t3) {
            (t3.type = "text/javascript"),
              t3.dispatchEvent(new CustomEvent("realscriptexecute", {})),
              t3.src && (t3.src += "");
          });
        } else
          e2.forEach(function (t3) {
            e.blacklist &&
              e.blacklist.some(function (e3) {
                return e3.test(t3.replace(l, "\\$&"));
              }) &&
              (e.blacklist = e.blacklist.filter(function (e3) {
                return !e3.test(t3.replace(l, "\\$&"));
              }));
            var i3 = o(r.blacklisted);
            i3.forEach(function (r2, n2) {
              var c2 = r2.src.search(t3);
              c2 > 0 &&
                (r.blacklisted.splice(n2, 1),
                (r2.type = "text/javascript"),
                r2.dispatchEvent(new CustomEvent("realscriptexecute", {})),
                r2.src && (r2.src += ""),
                document.head.appendChild(r2),
                (a2 = !1));
            });
          });
        return a2;
      };
    });
  }

  if (themeJsInit !== onload) {
    themeJsInit();
  }
})();
